import {
  Box,
  Button,
  ColumnLayout,
  Container,
  Header,
  SpaceBetween,
  StatusIndicator
} from '@amzn/awsui-components-react';
import { get } from 'lodash';
import React from 'react';
import { ApiExplorer } from '../../Domain/ApiExplorer/ApiExplorer';
import { useFlyout } from '../../hooks/useFlyout';
import { AnyObject } from '../../models/types';
import { getHealthyInstance } from '../../utils/helpers';
import {ElasticResponse, KibanaResponse} from './hooks/useClusterInfoQuery';
import { ErrorMessageComponent } from "../../ErrorHandler/errorMessageComponent";
import { ClusterAllocationExplain } from './StorageConsole/ClusterAllocationExplain/ClusterAllocationExplain';
import { ApiList }from "../../ErrorHandler/utils";

interface ClusterInfoProps {
  domainIdentifier: string;
  isLoading: boolean;
  ec2Instances: any[];
  elasticResponse: ElasticResponse;
  kibanaResponse: KibanaResponse;
  errorMessage: string;
  serverType: string;
  discoveryMode: string;
  handleRefreshData: Function;
  isFetching:boolean;
  esVersion: string;
}

const ValueWithLabel = ({ label, children }) => (
  <div>
    <Box margin={{ bottom: 'xxxs' }} color="text-label">
      {label}
    </Box>
    <div>{children}</div>
  </div>
);

const ClusterInfo = (props: ClusterInfoProps) => {
  const setFlyout = useFlyout();
  const clusterHealth: AnyObject =
    get(props, 'elasticResponse.cluster.health', {}) || {};

  const kibanaHealth: string =
    get(props, 'kibanaResponse.health', 'unknown') || '';
  const nodesInfo: any =
    Object.values(get(props, 'elasticResponse.cluster.state.nodes', {})) || {};
  const totalDis = new Set();
  nodesInfo.forEach((nodeInfo: any) => {
    totalDis.add(nodeInfo.attributes.di_number);
  });
  const excludedDi =
    get(
      props,
      'elasticResponse.cluster.settings.transient.cluster.routing.allocation.exclude.di_number',
      'N/A'
    ) || 'None';
  const excludedIps: string =
    get(
      props,
      'elasticResponse.cluster.settings.transient.cluster.routing.allocation.exclude._ip',
      ''
    ) || '';
  const clusterStats: AnyObject = get(
    props,
    'elasticResponse.cluster.stats',
    {}
  );
  const clusterFs = get(clusterStats, 'nodes.fs', {});
  const clusterAllocationExplain = get(
    props,
    'elasticResponse.cluster.allocationExplain',
    {
      error: '',
    }
  );


  return (
    <Container header={<Header variant="h2" actions={
            <SpaceBetween direction="horizontal" size="l">
              <Button iconName="refresh" disabled={props.isLoading || props.isFetching} onClick={props.handleRefreshData}  />
            </SpaceBetween>
          }>Cluster Info </Header>}>
       {props.errorMessage ? (
    <ErrorMessageComponent errorMessage={props.errorMessage} apiName={ApiList.CLUSTER_INFO}/>
  ) : (
      <ColumnLayout columns={4} variant="text-grid">
        <SpaceBetween size="l">
          <ValueWithLabel label="ES Health">
             {props.isLoading ? (
              '--'
            ) : `${clusterHealth.status}` === 'green' ? (
              <StatusIndicator type="success">green</StatusIndicator>
            ) : `${clusterHealth.status}` === 'red' ? (
              <StatusIndicator type="error">red</StatusIndicator>
            ) : `${clusterHealth.status}` === 'yellow' ? (
              'yellow'
            ) : (
              <div>
                unknown
                <div style={{ display: 'none' }}>{kibanaHealth}</div>
              </div>
            )}
          </ValueWithLabel>
          <ValueWithLabel label="Kibana Health">
            {props.isLoading ? (
              '--'
            ) : `${kibanaHealth}` === 'green' ? (
              <StatusIndicator type="success">green</StatusIndicator>
            ) : `${kibanaHealth}` === 'red' ? (
              <StatusIndicator type="error">red</StatusIndicator>
            ) : (
              <div>
                unknown
                <div style={{ display: 'none' }}>{kibanaHealth}</div>
              </div>
            )}
          </ValueWithLabel>
          <ValueWithLabel label="Server Type">
            {props.isLoading ? '--' : props.serverType}
          </ValueWithLabel>
          <ValueWithLabel label="Discovery Mode">
            {props.isLoading ? '--' : props.discoveryMode }
          </ValueWithLabel>
          <ValueWithLabel label="Disk">
            {props.isLoading
              ? '--'
              : `${clusterFs.available || 'N/A'} free / ${
                  clusterFs.total || 'N/A'
                } total`}
          </ValueWithLabel>
        </SpaceBetween>
        <SpaceBetween size="l">
          <ValueWithLabel label="Active Shards">
            {props.isLoading ? '--' : clusterHealth.active_shards || 'N/A'}
          </ValueWithLabel>
          <ValueWithLabel label="Active Primary Shards">
            {props.isLoading ? '--' : clusterHealth.active_primary_shards || 'N/A'}
          </ValueWithLabel>
          <ValueWithLabel label="Relocating shards">
            {props.isLoading ? '--' : clusterHealth.relocating_shards || 'N/A'}
          </ValueWithLabel>
          <ValueWithLabel label="Initializing shards">
            {props.isLoading ? '--' : clusterHealth.initializing_shards || 'N/A'}
          </ValueWithLabel>
          <ValueWithLabel label="Unassigned shards">
            <Button
              variant="link"
              onClick={() =>
                setFlyout({
                  title: 'Api Explorer',
                  size: 'm',
                  content: (
                    <ApiExplorer
                      apiRoot={{ value: '_cat' }}
                      apiPath={{ value: 'shards' }}
                      domainIdentifier={props.domainIdentifier}
                      instanceId={
                        getHealthyInstance(props.ec2Instances).instanceId
                      }
                      esVersion={props.esVersion}
                      nodeCount={props.ec2Instances.length}
                    />
                  ),
                })
              }
            >
              <span style={{ color: '#00a1c9' }}>
                {clusterHealth.unassigned_shards || '0'}
              </span>
            </Button>
          </ValueWithLabel>
        </SpaceBetween>
        <SpaceBetween size="l">
          <ValueWithLabel label="Total DIs">
            {props.isLoading ? '--' : totalDis.size}
          </ValueWithLabel>
          <ValueWithLabel label="Excluded DI">
            {props.isLoading ? '--' : excludedDi}
          </ValueWithLabel>
          <ValueWithLabel label="Excluded nodes">
            {props.isLoading
              ? '--'
              : excludedIps === ''
              ? 'N/A'
              : excludedIps.split(',').length}
          </ValueWithLabel>
          <ValueWithLabel label="Master nodes">
            {props.isLoading
              ? '--'
              : clusterHealth.number_of_nodes -
                clusterHealth.number_of_data_nodes}
          </ValueWithLabel>
          <ValueWithLabel label="Data nodes">
            {props.isLoading ? '--' : clusterHealth.number_of_data_nodes || 'N/A'}
          </ValueWithLabel>
        </SpaceBetween>
        <SpaceBetween size="l">
          <ValueWithLabel label="Allocation">
            {props.isLoading ? (
              '--'
            ) : clusterAllocationExplain &&
              typeof clusterAllocationExplain == 'object' ? (
              'error' in clusterAllocationExplain ? (
                'N/A'
              ) : (
                <Button
                  variant="link"
                  onClick={() =>
                    setFlyout({
                      title: 'Cluster Allocation Explain',
                      size: 'm',
                      content: (
                        <ClusterAllocationExplain
                          data={clusterAllocationExplain}
                        />
                      ),
                    })
                  }
                >
                  {'Error'}
                </Button>
              )
            ) : (
              'N/A'
            )}
          </ValueWithLabel>
          <ValueWithLabel label="Pending tasks">
            {props.isLoading ? (
              '--'
            ) : (
              <Button
                variant="link"
                onClick={() =>
                  setFlyout({
                    title: 'API Explorer',
                    size: 'm',
                    content: (
                      <ApiExplorer
                        apiRoot={{ value: '_cat' }}
                        apiPath={{
                          value: 'pending_tasks',
                        }}
                        domainIdentifier={props.domainIdentifier}
                        instanceId={
                          getHealthyInstance(props.ec2Instances).instanceId
                        }
                        esVersion={props.esVersion}
                        nodeCount={props.ec2Instances.length}
                      />
                    ),
                  })
                }
              >
                <span style={{ color: '#00a1c9' }}>
                  {clusterHealth.number_of_pending_tasks || '0'}
                </span>
              </Button>
            )}
          </ValueWithLabel>
        </SpaceBetween>
      </ColumnLayout>
       )}
    </Container>
  );
};

export { ClusterInfo };
