import {
  Autosuggest,
  Button,
  Container,
  Flashbar,
  FormField,
  Grid,
  Header,
  PropertyFilter,
  ButtonDropdown,
  Table,
  Pagination,
  Select,
  SpaceBetween,
} from '@amzn/awsui-components-react';
import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { CodeViewer } from '../../Components/Common/CodeViewer';
import { DISCLOSURE_MESSAGE } from '../../constants';
import { CommonDomainProps } from '../../models/types';
import { AutosuggestOptionsType } from './../../utils/constants';
import StatusIndicator from '@amzn/awsui-components-react/polaris/status-indicator/internal';
import { APIS, API_OPTIONS, NEW_API_OPTIONS, PAGE_SIZE_OPTIONS, catShardsColumnDefintions,
  CAT_SHARDS_DEFAULT_PREFERENCES, CAT_SHARDS_FILTERING_PROPERTIES, CAT_SHARDS_VISIBLE_CONTENT_OPTIONS,
  API_FILTERS_VALUE, API_FILTERS_ARGUEMENTS, type } from './constants';
import { useParams } from 'react-router';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { useExecuteApiMutation } from '../../hooks/useExecuteApiMutation';
import {BREADCRUMBS, PROPERTY_FILTERING_I18N_CONSTANTS} from '../../utils/constants';
import { PropertyFilterProperty } from '@amzn/awsui-collection-hooks/dist/cjs/interfaces';
import { useExecuteFilteredDataApiMutation } from '../../hooks/useExecuteFilteredDataApiMutation';
import { useResourceInfoQuery } from '../../pages/DomainDetails/hooks/useResourceInfoQuery';
import {EmptyState, getFilterCounterText, Preferences} from "../../utils/tablePreferences";

interface ApiExplorerProps extends CommonDomainProps {
  apiRoot: AutosuggestOptionsType;
  apiPath: AutosuggestOptionsType;
  domainIdentifier: string;
  instanceId: string;
  esVersion: string;
  nodeCount: number;
}

interface ApiExplorerUiState {
  apiRoot: AutosuggestOptionsType;
  apiPath: AutosuggestOptionsType;
}

const ApiExplorer = (props: ApiExplorerProps) => {
  const params = useParams();
  const clientId = params['clientId'];
  const domainName = params['domainName'];
  const { data } = useResourceInfoQuery({
    domainIdentifier: clientId + ':' + domainName,
  });
  const esOsVersion: string = get(data, 'domain.esVersion', 'OS_2.16');
  const [executeApi, { loading }] = useExecuteApiMutation();
  const [nextToken, setNextToken] = useState('')
  const [isListApi, setIsListApi] = useState(false)
  const [executeFilteredApi, { data: apiData, loading: filtered_loading }] = useExecuteFilteredDataApiMutation();
  let filteredResponse = get(apiData, 'executeGetFilteredDataAPI.data.truncated_output', '');
  let errorResponse = get(apiData, 'executeGetFilteredDataAPI.data.error', '');
  const [isDataTruncated, setIsDataTruncated] = useState(false)
  const preSignedUrl = get(apiData, 'executeGetFilteredDataAPI.data.presigned_url', '')
  const [apiResponse, setApiResponse] = useState([]);
  const [apiFilteredResponse, setFilteredApiResponse] = useState('');
  const [columnDefinition, setColumnDefinition] = useState(catShardsColumnDefintions)
  const [filterPropertyDefinition, setFilterPropertyDefinition] = useState(CAT_SHARDS_FILTERING_PROPERTIES)
  const [preferences, setPreferences] = useState(CAT_SHARDS_DEFAULT_PREFERENCES)
  const [visibleContentOptions, setVisibleContentOptions] = useState(CAT_SHARDS_VISIBLE_CONTENT_OPTIONS)
  const [flashbarItems, setFlashbarItems] = useState([]);
  const [filterArguement, setFilterArguement] = useState('')
  const [filterValue, setFilterValue] = useState('')
  const [previousResponse, setPreviousResponse] = useState([])
  const [isNextTokenAvailable, setIsNextTokenAvailable] = useState(false)
  
  const downloadFullResponse = () => {
    window.open(preSignedUrl, '_blank');
  };

  const dataItems = Array.isArray(apiResponse) ? apiResponse : [];

  const [formValues, setFormValues] = useState<ApiExplorerUiState>({
    apiRoot: props.apiRoot,
    apiPath: props.apiPath,
  });
  
  const [logContent, setLogContent] = useState('');

  useEffect(() => {
    setLogContent(filteredResponse);
  }, [filteredResponse]);

  useEffect(() => {
    const isDataTruncated = get(apiData, 'executeGetFilteredDataAPI.data.is_data_truncated', '')
    if (isDataTruncated) {
      setIsDataTruncated(true)
      setFlashbarItems([
        {
          type: 'error',
          content: 'This not complete response, please download complete response using presigned url given',
          dismissible: true,
          dismissLabel: 'Dismiss message',
          onDismiss: () => setFlashbarItems([]),
        },
      ]);
    }
  }, [apiData, filteredResponse]);

  function compareEsVersion(currentVersion: string, targetVersion: string): boolean {
    if (currentVersion.startsWith('ES')) {
      return false;
    }
    const parseVersion = (version: string) => version.split('_')[1].split('.').map(Number);
    const [currentMajor, currentMinor] = parseVersion(currentVersion);
    const [targetMajor, targetMinor] = parseVersion(targetVersion);
    if (currentMajor > targetMajor) return true;
    if (currentMajor === targetMajor && currentMinor >= targetMinor) return true;
    return false;
  }

  const defaultOperators = [':', '!:', '=', '!=', '>', '>=', '<', '<='];

  const createFilterProperties = (data) => {
    return Object.keys(data).map((key) => {
      return {
        propertyLabel: key,
        key: key,
        groupValuesLabel: key,
        operators: defaultOperators
      };
    }) as readonly PropertyFilterProperty[];
  };
  
  const createColumnDefinitions = (data) => {
    const keys = Object.keys(data);
    return keys.map((key) => ({
      id: key,
      header: key,
      cell: (e) => e[key],
      sortingField: key,
    }));
  };

  const createVisibleContentOptions = (data) => {
    return [
      {
        label: 'Options',
        options: Object.keys(data).map((key) => ({
          id: key,
          label: key.replace(/_/g, ' ').replace(/^./, str => str.toUpperCase())
        }))
      }
    ];
  };


  const createDefaultPreferences = (data) => {
    return {
      pageSize: 500,
      visibleContent: Object.keys(data),
      wraplines: true
    };
  };

  const excuteNextTokenApi = async () => {
    setIsListApi(true)
    if(nextToken !== null){
      const apiRoot = get(formValues, 'apiRoot.value', '_cat');
      const apiPath = get(formValues, 'apiPath.value', '').split('?')[0];
      let queryParams = `next_token=${nextToken}&size=2000&format=json`
      setPreviousResponse(apiResponse)
      executeFilteredApi({
      variables: {
        url: `${apiRoot}/${apiPath}?${queryParams}`,
        domainIdentifier: props.domainIdentifier,
        filterArguement: filterArguement,
        filterValue: filterValue,
        instanceId: props.instanceId,
      },
    });
    }

  }

  const handleExecuteApi = async () => {
    setLogContent('')
    setIsListApi(false)
    setPreviousResponse([])
    setFlashbarItems([])
    const apiRoot = get(formValues, 'apiRoot.value', '_cat');
    const apiPath = get(formValues, 'apiPath.value', '').split('?')[0];
    let queryParams = get(formValues, 'apiPath.value', 'format=json').split('?')[1];
    if (!queryParams){
      queryParams = 'format=json'
    }
    else{
      queryParams = `${queryParams}&format=json`
    }

    if(nextToken !== null && isNextTokenAvailable){
      queryParams = `next_token=${nextToken}`
    }
    if (apiRoot === '_list'){
      queryParams = `${queryParams}&size=2000`
      setIsListApi(true)
    }
    executeFilteredApi({
      variables: {
        url: `${apiRoot}/${apiPath}?${queryParams}`,
        domainIdentifier: props.domainIdentifier,
        filterArguement: filterArguement,
        filterValue: filterValue,
        instanceId: props.instanceId,
      },
    });
  };

  const {
    items,
    actions,
    filteredItemsCount,
    collectionProps,
    propertyFilterProps,
    paginationProps,
  } = useCollection(filtered_loading ? [] : dataItems, {
    propertyFiltering: {
      filteringProperties: filterPropertyDefinition,
      empty: (
        <EmptyState
          title="No logs"
          subtitle="No logs to display."
          action={<span></span>}
        />
      ),
      noMatch: (
        <EmptyState
          title="No matches"
          subtitle="We can’t find a match."
          action={
            <Button onClick={() => actions.setFiltering('')}>
              Clear filter
            </Button>
          }
        />
      ),
    },
    pagination: { pageSize: preferences.pageSize },
    sorting: {},
    selection: {},
  });

  useEffect(() => {
    const apiPath = get(formValues, 'apiPath.value', '').split('?')[0];
    if (isListApi){
      if (apiPath === 'indices'){
        filteredResponse = get(apiData, 'executeGetFilteredDataAPI.data.truncated_output[0].indices', '');
      } else{
        filteredResponse = get(apiData, 'executeGetFilteredDataAPI.data.truncated_output[0].shards', '');
      }
      let token_value = get(apiData, 'executeGetFilteredDataAPI.data.truncated_output[0].next_token', '');
      setNextToken(token_value)
      const combinedResponse = [...previousResponse, ...filteredResponse];
      setPreviousResponse(combinedResponse);
      setApiResponse(combinedResponse);
    } else{
      setPreviousResponse([])
      setApiResponse(filteredResponse)
    }
  }, [filteredResponse]);

  useEffect(() => {
    let sample_data = {}
    if (logContent === ''){
      sample_data = {
                      "index": "",
                      "shard": "0",
                      "prirep": "p",
                      "state": "UNASSIGNED",
                      "docs": null,
                      "store": null,
                      "ip": null,
                      "node": null
                    }
    } else {
      sample_data = apiResponse[0]
    }

    if (sample_data !== undefined && (formValues.apiRoot.value === '_cat' || formValues.apiRoot.value === '_list')){
      const column_definition = createColumnDefinitions(sample_data)
      setColumnDefinition(column_definition)
      let filtering_properties = createFilterProperties(sample_data)
      setFilterPropertyDefinition(filtering_properties)
      let preferences = createDefaultPreferences(sample_data)
      setPreferences(preferences)
      let visible_content_options = createVisibleContentOptions(sample_data)
      setVisibleContentOptions(visible_content_options)
    }
  }, [apiData, filteredResponse, logContent, filtered_loading]);

  useEffect(() => {
    if (props.apiRoot && props.apiPath) {
      handleExecuteApi();
    }
  }, [props.apiRoot, props.apiPath]);

  useEffect(() => {
    setFilterValue('')
    setFilterArguement('')
  }, [formValues.apiPath.value, formValues.apiRoot.value]);
  
  return (
    <Container
      header={
        <div>
          <Header variant="h2">API Explorer</Header>
          <Flashbar items={[
            {
              type: "error",
              content: DISCLOSURE_MESSAGE,
            }
          ]} />
        </div>
      }
    >
      <SpaceBetween direction="vertical" size="l">
        <Grid
          gridDefinition={[
            { colspan: { default: 4, xxs: 4 } },
            { colspan: { default: 4, xxs: 4 } },
            { colspan: { default: 4, xxs: 4 } },
            { colspan: { default: 4, xxs: 4 } },
            { colspan: { default: 7, xxs: 2 } },
          ]}
        >
          <FormField
            constraintText="Choose root API from list. These APIS are at cluster level i.e (http://localhost:9200/_cat/)"
            label={<strong>Root API</strong>}
          >
            <Select
              selectedOption={formValues.apiRoot}
              onChange={(selectedApiRoot) => {
                setFormValues((state) => ({
                  ...state,
                  apiRoot: selectedApiRoot.detail
                    .selectedOption as AutosuggestOptionsType,
                  apiPath: { value: '' } as AutosuggestOptionsType,
                }));
              }}
              options={
                compareEsVersion(esOsVersion, 'OS_2.17')
                  ? NEW_API_OPTIONS 
                  : API_OPTIONS
              }
              selectedAriaLabel="Selected"
              placeholder="Choose root API"
            />
          </FormField>
          <FormField
            constraintText="Choose API path or add custom path"
            label={<strong>Path</strong>}
          >
            <Autosuggest
              onChange={(selectedApiPath) => {
                setFormValues((state) => ({
                  ...state,
                  apiPath: selectedApiPath.detail,
                  filterArguement: { value: 'state' } as AutosuggestOptionsType,
                }));
              }}
              value={formValues.apiPath.value}
              options={
                (formValues.apiRoot.value === '_cat' && props.nodeCount >= 200)
                      ? APIS.cat.filter(
                          (option) =>
                            !option.value.startsWith('indices') &&
                            !option.value.startsWith('shards')
                        )
                      : APIS[formValues.apiRoot.value.substring(1)]
              }
              enteredTextLabel={(value) => `Current Path: "${value}"`}
              ariaLabel="Autosuggest example with suggestions"
              placeholder="Choose or create path"
              empty="Choose or create path"
            />
          </FormField>
          {formValues.apiRoot.value === '_cat' && (formValues.apiPath.value === 'shards' || formValues.apiPath.value === 'indices') && (<FormField
            constraintText="Choose filter Arguement"
            label={<strong>Filter Arguement</strong>}
          >
            <Autosuggest
              onChange={({ detail }) => setFilterArguement(detail.value)}
              value={filterArguement}
              options={API_FILTERS_ARGUEMENTS[formValues.apiPath.value]}
              enteredTextLabel={(value) => `Current Filter Arguement: "${value}"`}
              ariaLabel="Autosuggest example with suggestions"
              placeholder="Choose or write filter arguement"
              empty="Choose or write filter arguement"
            />
          </FormField>)}
          {formValues.apiRoot.value === '_cat' && (formValues.apiPath.value === 'shards' || formValues.apiPath.value === 'indices') && (<FormField
            constraintText="Choose filter Value"
            label={<strong>Filter Value</strong>}
          >
            <Autosuggest
              onChange={({ detail }) => setFilterValue(detail.value)}
              value={filterValue}
              options={API_FILTERS_VALUE[formValues.apiPath.value]}
              enteredTextLabel={(value) => `Current Filter Value: "${value}"`}
              ariaLabel="Autosuggest example with suggestions"
              placeholder="Choose or write filter value"
              empty="Choose or write filter value"
            />
          </FormField>)}
          <div style={{ marginTop: "26px" }}>
            <Button variant="normal" onClick={handleExecuteApi} loading={filtered_loading}>
              Execute
            </Button>
          </div>
        </Grid>
        {(formValues.apiRoot.value !== '_cat' && formValues.apiRoot.value !== '_list') && (
          <div>
            {(logContent === '' && errorResponse === '') ? (
              <StatusIndicator type="loading">Loading API response</StatusIndicator>
            ) : (
              <SpaceBetween size="l" direction="vertical">
                {isDataTruncated && (
                  <Flashbar items={flashbarItems} />
                )}
                {formValues.apiRoot.value !== '_list' && (<SpaceBetween direction="horizontal" size="l">
                  <Button onClick={downloadFullResponse}>
                    Download Full Response
                  </Button>
                </SpaceBetween>)}
                <CodeViewer
                  codeData={
                    typeof apiResponse === 'string'
                      ? apiResponse
                      : JSON.stringify(apiResponse, null, 4)
                  }
                  language={{ label: 'javascript', value: 'javascript' }}
                  theme={{ label: 'coy', value: 'coy' }}
                />
              </SpaceBetween>
            )}
          </div>
        )}
        {apiResponse && (formValues.apiRoot.value === '_cat' || formValues.apiRoot.value === '_list') && (<div
            style={{
              fontFamily: '"Roboto Mono",Consolas,Menlo,Courier,monospace',
            }}
          >
            {(logContent === '' && errorResponse === '') ? (
              <StatusIndicator type="loading">Loading API response</StatusIndicator>
            ) : (
              <SpaceBetween size="l" direction="vertical">
                {isDataTruncated &&
                    (<Flashbar items={flashbarItems} />)
                }
                <Table
                  header={
                    <Header
                        counter={`(${apiResponse.length})`}
                        actions={
                          <SpaceBetween direction="horizontal" size="l">
                            <Button onClick={downloadFullResponse}>
                              Download Full Response
                            </Button>
                            {formValues.apiRoot.value === '_list' && (
                              <Button onClick={excuteNextTokenApi}>
                                Next Token Response
                              </Button>
                            )}
                          </SpaceBetween>
                        }
                    >
                      API Response
                    </Header>
                  }
                  columnDefinitions={columnDefinition}
                  stickyHeader={true}
                  resizableColumns={false}
                  loading={filtered_loading}
                  visibleColumns={preferences.visibleContent}
                  items={items}
                  pagination={
                    <Pagination
                      {...paginationProps}
                      ariaLabels={{
                        nextPageLabel: 'Next page',
                        previousPageLabel: 'Previous page',
                        pageLabel: (pageNumber) => `Page ${pageNumber} of all pages`,
                      }}
                    />
                  }
                  filter={
                    <div className="input-container">
                      <PropertyFilter
                        className="input-filter"
                        i18nStrings={PROPERTY_FILTERING_I18N_CONSTANTS}
                        {...propertyFilterProps}
                        countText={getFilterCounterText(filteredItemsCount)}
                      />
                    </div>
                  }
                  preferences={
                    <Preferences
                      preferences={preferences}
                      setPreferences={setPreferences}
                      disabled={false}
                      pageSizeOptions={PAGE_SIZE_OPTIONS}
                      visibleContentOptions={visibleContentOptions}
                    />
                  }
                />
              </SpaceBetween>
            )}
          </div>)}
      </SpaceBetween>
    </Container>
  );
};

ApiExplorer.defaultProps = {
  apiRoot: { value: '_cat' },
  apiPath: { value: 'master' },
  filterArguement: { value: ''},
  filterValue: { value: ''}
} as Partial<ApiExplorerProps>;

export { ApiExplorer };
